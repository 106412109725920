import React, { useMemo } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import QRCode from "react-qr-code";
import createPDF417 from "./min/pdf417-min";


export default function Barcode({LabelType, text, blockWidth, blockHeight}) {
    const debounceText = useDebounce(text, 1000);
    const loaded = text === debounceText;
    const barcodeSrc = useMemo(() => {
        return generateBarcode(debounceText, blockWidth, blockHeight);
    }, [debounceText, blockWidth, blockHeight]);

    return (
        <div className="card">
            <div className="card-body px-3 py-5 text-center">
                <p className={"text-center"}>
                    <strong>{LabelType === "QR" ? "QR Code" : "Data Matrix"}:</strong>
                    {debounceText}
                </p>
                {
                    LabelType === "QR" ? (
                        <QRCode value={text}/>
                    ) : (
                        loaded ? <img className={'img-fluid'} alt={text} src={barcodeSrc}/> :
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    )
                }

            </div>
        </div>
    )
}

function drawBarcode(canvas, barcodeMatrix, blockWidth, blockHeight) {
    const ctx = canvas.getContext('2d');
    let positionY = 0;
    for (let row = 0; row < barcodeMatrix.num_rows; row += 1) {
        let positionX = 0;
        for (let col = 0; col < barcodeMatrix.num_cols; col += 1) {
            if (barcodeMatrix.bcode[row][col] === '1') {
                ctx.fillStyle = '#000';
            } else {
                ctx.fillStyle = '#FFF';
            }
            ctx.fillRect(positionX, positionY, blockWidth, blockHeight);
            positionX += blockWidth;
        }
        positionY += blockHeight;
    }
}

function generateBarcode(text, blockWidth, blockHeight) {
    const canvas = document.createElement('canvas');
    const PDF417= createPDF417();
    PDF417.init(text);
    const barcodeMatrix = PDF417.getBarcodeArray();
    canvas.width = blockWidth * barcodeMatrix.num_cols;
    canvas.height = blockHeight * barcodeMatrix.num_rows;
    drawBarcode(canvas, barcodeMatrix, blockWidth, blockHeight);
    return canvas.toDataURL();
}

