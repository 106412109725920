import React from 'react';
import Barcode from './Barcode';

export default function App() {
    const DEFAULT_ITEM = {
        ItemID: "123456789012",
        ItemIDType: "ASIN",
        ItemQuantity: 72,
        LabelQuantity: 1
    }
    const [formData, setFormData] = React.useState({
        ShipmentID: "FBA123456",
        LabelType: "DATAMATRIX",
        items: [Object.assign({}, DEFAULT_ITEM)]
    })

    // "AMZN,PO:FBA23JFC2G,UPC:776403045720,QTY:10, UPC:847603044631,QTY:24"
    function handleItemChange(e) {
        const [index, field] = e.target.name.split('-');
        if (index !== undefined) {
            const newItems = [...formData.items];
            newItems[index][field] = e.target.value.toUpperCase();
            setFormData({...formData, items: newItems});
        }
    }

    function handleChange(e) {
        setFormData({...formData, [e.target.name]: e.target.value.toUpperCase()});
    }

    function handleAddItem() {
        setFormData({
            ...formData,
            items: [...formData.items, Object.assign({}, DEFAULT_ITEM)]
        });
    }

    function handleRemoveItem(index) {
        if (index !== undefined) {
            setFormData({
                ...formData,
                items: formData.items.filter((item, i) => i !== index)
            });
        } else {
            setFormData({
                ...formData,
                items: formData.items.slice(0, -1)
            });
        }
    }

    // https://sellercentral.amazon.com/help/hub/reference/GJWALJCN6JKWJX5A
    // The barcode must contain the following information in the order listed:
    // Begin with the characters AMZN
    // FBA Shipment ID, also referred to as the PO (Code: PO)
    // Item ID (Code: ASIN, UPC, EAN, ISBN, or FNSKU)
    // Quantity (Code: QTY)
    // Separate codes and their values with a colon, and separate each segment with a comma—for example:
// Initialize barcodeText and debouncedBarcodeText states
    let barcodeTextParts = ["AMZN", `PO:${formData.ShipmentID}`];
    formData.items.forEach(({ItemIDType, ItemID, ItemQuantity}, index) => {
        barcodeTextParts.push(`${ItemIDType}:${ItemID}`);
        barcodeTextParts.push(`QTY:${ItemQuantity}`);
    });
    let barcodeText = barcodeTextParts.join(',');
    const preventDelete = formData.items.length < 2;

    return (
        <div className="container">
            <div className="row g-2">
                <div className="col-5">
                    <div className="input-group">
                        <span className="input-group-text">FBA Shipment:</span>
                        <input className={"form-control"} type="text" name={"ShipmentID"}
                               value={formData.ShipmentID}
                               onChange={handleChange}/>
                    </div>
                    <div className="input-group">
                        <span className="input-group-text">Label Type:</span>
                        <select className={"form-control"} name={`LabelType`}
                                value={formData.LabelType}
                                onChange={handleChange}>
                            <option value="QR">QR Code</option>
                            <option value="DATAMATRIX">Data Matrix</option>
                        </select>
                    </div>


                    <Barcode LabelType={formData.LabelType} blockHeight={20} blockWidth={35}
                             text={barcodeText}/>
                </div>
                <div className="col-7">
                    <div className="text-end">
                        <button
                            className="btn btn-sm btn-success"
                            onClick={handleAddItem}>Add Item
                        </button>
                    </div>
                    {
                        formData.items.map(({ItemQuantity, ItemID, ItemIDType}, index) => (
                            <div className={"input-group"} key={index}>
                                <strong className="input-group-text">Item {index + 1}</strong>
                                <span className="input-group-text">ItemID:</span>
                                <select style={{maxWidth: "80px"}} className={"form-control"}
                                        name={`${index}-ItemIDType`}
                                        value={ItemIDType}
                                        onChange={handleItemChange}>
                                    <option value="FNSKU">FNSKU:</option>
                                    <option value="ASIN">ASIN:</option>
                                    <option value="UPC">UPC:</option>
                                    <option value="EAN">EAN:</option>
                                    <option value="ISBN">ISBN:</option>
                                </select>
                                <input placeholder={`${ItemIDType}: *****`} className={"form-control"}
                                       type="text"
                                       name={`${index}-ItemID`} value={ItemID}
                                       onChange={handleItemChange}/>
                                <span className="input-group-text">Box QTY:</span>
                                <input style={{maxWidth: "80px"}} className={"form-control"} type="number" min={1}
                                       max={150} step={1}
                                       name={`${index}-ItemQuantity`}
                                       value={ItemQuantity}
                                       onChange={handleItemChange}/>
                                <button
                                    className={`btn btn-sm ${preventDelete ? 'disabled btn-disabled' : 'btn-outline-danger'}`}
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (!preventDelete) {
                                            handleRemoveItem(index)
                                        }
                                    }}
                                    id="button-addon1">X
                                </button>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
}