export default function Footer() {
    return (
        <footer className="navbar fixed-bottom bg-body-tertiary">
            <div className="container justify-content-center">
                <p className="text-muted text-center">
                    Made by an <a href="https://www.andersonassociates.net/">Amazon Manufacturers
                    Representative</a>
                    <br/>
                    Blair Anderson © 2024, All rights reserved.
                </p>
            </div>
        </footer>
    )
}